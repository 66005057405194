import { gql, useSubscription } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSocket } from "../context/SocketContext";

const SIGNAL = "signal";
const ENTRIES = "entry";

export type SocketType = "signal" | "entry";

export type EntryType = {
	type: "entry";
	id: string;
	title: string;
	body: string;
	datePublished: Date;
	summary: string;
	ctNode: {
		title: string;
		type: string;
		nid: string;
	};
	comment: {
		title: string;
		body: string;
	};
};

export type SignalType = {
	uiLive?: string;
	pathLive?: string;
	nameLive?: string;
	codeLive?: string;
};
function sortByDate(a: EntryType, b: EntryType) {
	return (
		new Date(b.datePublished).getTime() - new Date(a.datePublished).getTime()
	);
}

export function useSocketSignal({ id = "", defaultPreview = {} }) {
	const socket: any = useSocket();
	const [updateSignal, setUpdateSignal] = useState<any>();
	const [signal, setSignal] = useState<any>(defaultPreview);

	useEffect(() => {
		function onMessageReceived(msg: any) {
			if (msg.type === SIGNAL) {
				setUpdateSignal(msg);
			}
		}

		if (socket) {
			socket.on("message", onMessageReceived);
		}

		return () => {
			if (socket) {
				socket.off("message", onMessageReceived);
			}
		};
	}, [id, socket]);

	function handleUpdate(callback = () => {}) {
		setUpdateSignal(null);
		callback();
	}

	function handlePrewiewUpdates() {
		setSignal(updateSignal);
		setUpdateSignal(null);
	}

	return { handleUpdate, updateSignal, signal, handlePrewiewUpdates };
}

export function useSocketEntries({ id = "" }) {
	const socket: any = useSocket();
	const [updateEntries, setUpdateEntries] = useState<any>([]);
	const [entries, setEntries] = useState<any[]>([]);

	useEffect(() => {
		function onMessageReceived(msg: any) {
			if (msg.type === ENTRIES) {
				setUpdateEntries((prev: any) => {
					const entryExists =
						prev?.some((item: any) => item.eid === msg.eid) ?? false;
					return entryExists ? prev : [msg, ...(prev ?? [])];
				});
			}
		}

		if (socket) {
			socket.on("message", onMessageReceived);
		}

		return () => {
			if (socket) {
				socket.off("message", onMessageReceived);
			}
		};
	}, [id, socket]);

	function handleUpdate(callback = () => {}) {
		setUpdateEntries(null);
		callback();
	}

	function handlePrewiewUpdates() {
		setEntries((prev) => [...prev, ...updateEntries].sort(sortByDate));
		setUpdateEntries([]);
	}

	return {
		handleUpdate,
		updateEntries,
		entries,
		handlePrewiewUpdates,
		counter: updateEntries?.length ?? 0,
	};
}
