import dayjs from "dayjs";
import { hourToSeconds, secondsToHours } from "@/app/helpers/timeHelpers/timeHelpers";
import toCDMX from "@/app/helpers/timeHelpers/toCDMXHour";

export const ActualProgramHelperToCardLive = (shows: any) => {
	if (shows && shows?.length > 0) {
		const currentMexHour = toCDMX(dayjs()).format("HH:mm:ss");
		const timeSearchInSeconds = hourToSeconds(currentMexHour);
		let currentProgram = null;
		let showIndex = 0;

		do {
			const showStartTime = hourToSeconds(shows[showIndex]?.airtime);
			const showEndTime = showStartTime + shows[showIndex]?.duration * 60;

			if (
				timeSearchInSeconds >= showStartTime &&
				timeSearchInSeconds <= showEndTime
			) {
				currentProgram = {
					...shows[showIndex],
					airtimeEnd: secondsToHours(showEndTime),
				};
			}

			showIndex++;
		} while (currentProgram === null);
		return currentProgram;
	} else {
		return {
			descripcion: "No Disponible",
			airtime: "",
			airtimeEnd: "",
			title: "No Disponible",
		};
	}
};
