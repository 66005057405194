"use client";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import styles from "@/app/styles/Grid/Grid.module.scss";
import { ActualProgramHelperToCardLive } from "@/app/helpers/actualProgramHelperV2/actualProgramHelperV2";
import { TimeDisplay } from "@/app/components/molecules/EnVivo/TimeDisplay";
import { SocialMediaEnVivo } from "@/app/components/molecules/EnVivo/SocialMediaEnVivo";

const Grid = dynamic(() => import("./Grid"));
const PlayerStreaming = dynamic(
	() => import("@/app/components/molecules/PlayerStreaming/PlayerStreaming")
);

export default function GridProvider({
	channels,
	values,
	blockDataLayer,
	params,
}: any) {
	const currentChannel = channels?.find(
		(item: any) =>
			item?.canal_name?.toLowerCase()?.replace(" ", "") === params?.canal
	);
	const [live, setLive] = useState({
		ui: currentChannel?.ui_live ?? channels?.[0]?.ui_live,
		uid: currentChannel?.uid ?? channels?.[0]?.uid,
		path: currentChannel?.path_live ?? channels?.[0]?.path_live,
	});
	const [viewGrid, setViewGrid] = useState(true);
	const [currentShow, setCurrentShow] = useState<any>(
		ActualProgramHelperToCardLive(
			channels.find((channel: any) => channel.path_live === live.path)?.shows
		)
	);

	useEffect(() => {
		setCurrentShow(
			ActualProgramHelperToCardLive(
				channels.find((channel: any) => channel.path_live === live.path)?.shows
			)
		);
	}, [live, channels]);

	/**
	 * This adds the query string to the url after the user has navigated to the page /en-vivo/
	 * Adds: ?canal=forotv|noticieros
	 */
	useEffect(() => {
		const pathName = window.location.pathname;
		if (pathName === "/en-vivo/") {
			console.log(pathName);
			const param = live?.path?.replace("-en-vivo", "");
			window.history.replaceState(
				window.history.state,
				"",
				`${window.location.origin}${pathName}?canal=${param}`
			);
		}
	}, [live]);
	return (
		<div className={styles.programming_container}>
			{/* Live & En Vivo Text */}
			<div>
				<PlayerStreaming {...live} />
				<TimeDisplay
					currentShow={currentShow}
					setViewGrid={setViewGrid}
					viewGrid={viewGrid}
				/>
			</div>

			{/* Grid of channel & Social */}
			<div className={styles.div_grids}>
				<Grid
					channels={channels}
					data={values}
					setLive={setLive}
					live={live}
					viewGrid={viewGrid}
					currentShow={currentShow}
					blockDataLayer={blockDataLayer}
				/>

				<SocialMediaEnVivo blockDataLayer={blockDataLayer} />
			</div>
		</div>
	);
}
