export const partyColors = [
	{
		name: "PRI",
		color: "#E21F26",
	},
	{
		name: "PRI_V2",
		color: "#00a651",
	},
	{
		name: "PRD",
		color: "#FEDA1D",
	},
	{
		name: "PAN",
		color: "#2654A5",
	},
	{
		name: "MORENA",
		color: "#801517",
	},
	{
		name: "INDEPENDIENTE",
		color: "#808281",
	},
	{
		name: "MC",
		color: "#F27A21",
	},
	{
		name: "CONVERGENCIA",
		color: "#F27A21",
	},
	{
		name: "PES",
		color: "#422059",
	},
	{
		name: "PVEM",
		color: "#35B34B",
	},
	{
		name: "PAS",
		color: "#808281",
	},
	{
		name: "PT",
		color: "#9f6A65",
	},
	{
		name: "PUP",
		color: "#89D398",
	},
	{
		name: "PAM",
		color: "#FF1860",
	},
	{
		name: "UDC",
		color: "#CC6C2B",
	},
	{
		name: "PPS",
		color: "#D94EDD",
	},
	{
		name: "PARM",
		color: "#EFCB59",
	},
	{
		name: "FCRN",
		color: "#353535",
	},
	{
		name:'DS',
		color: "#D94EDD",
	},
	{
		name:'PANAL',
		color: "#40b3ab",
	},
	{
		name:'PAN_PRI_PRD',
		color:'#00C1CF'
	}
];
