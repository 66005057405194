import { gql } from "@apollo/client";

export default gql`
	query GetChannelsGrids($params: [String]!) {
		getChannelsGrids(params: $params) {
			PROGRAMACION {
				CANAL {
					SHOWS {
						airdate
						airtime
						channel
						code
						description
						duration
						idtvshow
						mcpid
						title
					}
					logo
					title
				}
				FECHA
			}
			codigo_canal
			error_message
		}
	}
`;
