"use client";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { usePathname } from "next/navigation";
declare const window: WindowWithDataLayer;

export default function SendDataLayer({
	url,
	blockData,
	title,
	children,
	cardPosition,
	className,
}: any) {
	const path = usePathname();

	return (
		<div
			onClick={() =>
				handleDataLayerOnClick(blockData, cardPosition, path, false)
			}
			className={className}
		>
			{children}
		</div>
	);
}
