"use client";
import { useState, useRef, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Keyboard } from "swiper/modules";
import { ToggleProvider } from "@/app/context/ToggleContext";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import carousel from "@/app/styles/Molecules/Carousel.module.scss";
import VideoComponent from "@/app/components/molecules/cards/CardVideo/CardVideo";

declare let window: any;

export default function Carousel({
	slides,
	deviceSize,
	ctaCard,
	modalID,
}: any) {
	const [selectedIndex, setSelectedIndex] = useState<any>(null);
	const [activeIndex, setActiveIndex] = useState<any>(null);
	const [isMuted, setIsMuted] = useState(true);
	const [isPlaying, setIsPlaying] = useState(true);
	let touchStart = null;
	let touchEnd = null;
	const swiperRef = useRef(null);
	const slideCheck = () => {
		const prevSlide = document.querySelector(
			".swiper-wrapper .swiper-slide-prev"
		);
		const previousSlides: HTMLElement[] = [];
		const nextSlide = document.querySelector(
			".swiper-wrapper .swiper-slide-next"
		);
		const nextSlides: HTMLElement[] = [];

		if (prevSlide) {
			let currentSlide = prevSlide as HTMLElement;
			for (let i = 0; i < 6; i++) {
				currentSlide = currentSlide?.previousElementSibling as HTMLElement;
				if (currentSlide && currentSlide.classList.contains("swiper-slide")) {
					previousSlides.push(currentSlide);
				} else {
					break;
				}
			}
		}

		if (nextSlide) {
			let currentSlide = nextSlide as HTMLElement;
			for (let i = 0; i < 6; i++) {
				currentSlide = currentSlide?.nextElementSibling as HTMLElement;
				if (currentSlide && currentSlide.classList.contains("swiper-slide")) {
					nextSlides.push(currentSlide);
				} else {
					break;
				}
			}
		}

		previousSlides.forEach((slide) => {
			slide.classList.remove("cta-slide");
		});

		previousSlides.slice(1).map((slide) => slide.classList.add("cta-slide"));

		nextSlides.forEach((slide) => {
			slide.classList.remove("cta-slide");
		});

		nextSlides.slice(1).map((slide) => slide.classList.add("cta-slide"));
	};

	const onTouchStart = (e: any) => {
		touchEnd = null;
		touchStart = e?.targetTouches[0]?.clientX;
	};

	const onTouchMove = (e: any) => {
		touchEnd = e?.targetTouches[0]?.clientX;
	};

	const changeSlide = (activeIndex: number, previousIndex: number) => {
		slideCheck();
		if (deviceSize !== "desktop") {
			const prevPlayerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
				`player-${modalID}-${previousIndex}`
			);
			if (prevPlayerInstance) {
				window?.VideoSDK?.AMP?.mute(`player-${modalID}-${previousIndex}`);
			}
			setIsMuted(true);
		}
		if (slides?.length !== previousIndex) {
			const prevPlayerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
				`player-${modalID}-${previousIndex}`
			);
			if (prevPlayerInstance) {
				prevPlayerInstance.pause();
			}
		}
		if (deviceSize !== "desktop") {
			try {
				const prevPlayerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
					`player-${modalID}-${activeIndex - 2}`
				);
				if (prevPlayerInstance) {
					prevPlayerInstance.destroy();
				}
				const nextPlayerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
					`player-${modalID}-${activeIndex + 2}`
				);
				if (nextPlayerInstance) {
					nextPlayerInstance.destroy();
				}
			} catch (e) {}
		}
		setActiveIndex(activeIndex);
		if (deviceSize !== "desktop") {
			const activePlayerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
				`player-${modalID}-${activeIndex}`
			);
			if (activePlayerInstance) {
				window?.VideoSDK?.AMP?.mute(`player-${modalID}-${activeIndex}`);
			}
		}
	};

	const audioVideo = useCallback(
		(activeIndex: number, muted: boolean) => {
			setIsMuted(muted);
			if (muted === false) {
				window?.VideoSDK?.AMP?.unmute(`player-${modalID}-${activeIndex}`);
			} else {
				window?.VideoSDK?.AMP?.mute(`player-${modalID}-${activeIndex}`);
			}
		},
		[modalID]
	);

	const playVideo = useCallback(
		(activeIndex: number) => {
			let to: any = null;
			const playerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
				`player-${modalID}-${activeIndex}`
			);
			if (playerInstance) {
				playerInstance.play();
				audioVideo(activeIndex, isMuted);
				playerInstance.setMute(true);
			} else {
				to = setTimeout(() => {
					const delayedPlayerInstance =
						window?.VideoSDK?.AMP?.getAMPlayerInstance(
							`player-${modalID}-${activeIndex}`
						);
					if (delayedPlayerInstance) {
						delayedPlayerInstance.play();
						delayedPlayerInstance.setMute(true);
					}
				}, 2000);
			}
			return to;
		},
		[audioVideo, isMuted, modalID]
	);

	const togglePlay = (activeIndex: number) => {
		const playerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
			`player-${modalID}-${activeIndex}`
		);
		if (playerInstance) {
			if (!isPlaying) {
				playerInstance.play();
			} else {
				playerInstance.pause();
			}
			setIsPlaying(!isPlaying);
		}
	};

	const onVideoEnded = () => {
		const playerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(
			`player-${modalID}-${activeIndex}`
		);
		if (playerInstance) {
			if (isPlaying && !playerInstance.muted) {
				setIsMuted(false);
			}
		} else {
			setIsMuted(true);
		}
	};

	const handleSlideClick = () => {
		slideCheck();
		// @ts-ignore
		if (swiperRef.current && swiperRef.current.swiper) {
			// @ts-ignore
			const swiperInstance = swiperRef.current.swiper;
			const selectedIndex = swiperInstance.clickedIndex;
			setSelectedIndex(selectedIndex);
			swiperInstance.slideTo(selectedIndex);
		}
	};

	useEffect(() => {
		setIsMuted(true);
		slideCheck();
		const to = playVideo(activeIndex);
		audioVideo(activeIndex, isMuted);

		return () => clearTimeout(to);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIndex, playVideo]);

	useEffect(() => {
		setIsMuted(true);
	}, []);

	return (
		<div
			className={`${carousel["carousel__wrapper"]}`}
			onTouchMove={onTouchMove}
			onTouchStart={onTouchStart}
		>
			{(deviceSize === "desktop" || deviceSize === "tablet") && (
				<Swiper
					ref={swiperRef}
					className={carousel.swiper}
					initialSlide={2}
					speed={500}
					grabCursor
					centeredSlides
					slidesPerView={"auto"}
					preventInteractionOnTransition={true}
					modules={[EffectCoverflow, Navigation, Keyboard]}
					keyboard={{
						enabled: true,
					}}
					navigation
					effect={"coverflow"}
					coverflowEffect={{
						rotate: 0,
						stretch: -60,
						depth: 250,
						modifier: 2,
						slideShadows: true,
					}}
					onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
					onSwiper={(swiper) => setActiveIndex(swiper.activeIndex)}
					onTransitionEnd={({ activeIndex, previousIndex }) => {
						changeSlide(activeIndex, previousIndex);
					}}
				>
					{slides.map((item: any, index: number) => (
						<SwiperSlide
							key={`SwiperSlide-${index}`}
							onClick={(swiper) => handleSlideClick()}
						>
							<ToggleProvider>
								<VideoComponent
									item={item}
									index={index}
									activeIndex={activeIndex}
									isMuted={isMuted}
									togglePlay={togglePlay}
									audioVideo={audioVideo}
									onVideoEnded={onVideoEnded}
									modalID={modalID}
									isDiscover={false}
								/>
							</ToggleProvider>
						</SwiperSlide>
					))}
					<SwiperSlide onClick={(swiper) => handleSlideClick()}>
						<div className={`${carousel["carousel__ctaCard"]}`}>
							<span>{ctaCard?.title}</span>
							<a href={ctaCard?.url} target={ctaCard?.options}>
								{ctaCard?.buttonText}
							</a>
						</div>
					</SwiperSlide>
				</Swiper>
			)}
			{deviceSize === "mobile" && (
				<Swiper
					className={carousel.swiper}
					ref={swiperRef}
					initialSlide={0}
					speed={500}
					rewind
					grabCursor
					centeredSlides
					slidesPerView={1}
					onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
					onSwiper={(swiper) => setActiveIndex(swiper.activeIndex)}
				>
					{slides.map((item: any, index: number) => (
						<SwiperSlide key={`SwiperSlide-${index}`}>
							<ToggleProvider>
								<VideoComponent
									item={item}
									index={index}
									activeIndex={activeIndex}
									isMuted={isMuted}
									togglePlay={togglePlay}
									audioVideo={audioVideo}
									onVideoEnded={onVideoEnded}
									modalID={modalID}
									isDiscover={false}
								/>
							</ToggleProvider>
						</SwiperSlide>
					))}
					<SwiperSlide>
						<div className={`${carousel["carousel__ctaCard"]}`}>
							<span>{ctaCard?.title}</span>
							<a href={ctaCard?.url} target={ctaCard?.options}>
								{ctaCard?.buttonText}
							</a>
						</div>
					</SwiperSlide>
				</Swiper>
			)}
		</div>
	);
}
