
import { notFound } from "next/navigation";

import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetEnVivo from "@/app/queries/getEnVivo";

import type { EnVivoResponse } from "@/app/types";

export const getEnVivoByPath = async (pathUrl: string) => {

	try {
		const { data, ...rest } = await fetchApolloData(GetEnVivo, {
			path: pathUrl,
		});

		if (rest?.errors) {
			console.error("ERROR <<<");
			if (rest?.errors instanceof Array) {
				rest?.errors?.forEach((error: any) => {
					switch (error?.extensions?.code) {
						case "INTERNAL_SERVER_ERROR":
							if (
								error?.message === "All promises were rejected" ||
								error?.message === "Invalid response"
							) {
								console.error(error?.extensions);
								notFound();
							}
							break;
						case 502:
							throw new Error(error?.message);
						case 404:
							notFound();
					}
				});
			} else {
				throw new Error(`${rest?.errors?.message} - [ERR]`);
			}
		}
		const pathResponse:EnVivoResponse = data;

		return pathResponse;
	} catch (error) {
		console.log(error);
		return null;
	}
};
