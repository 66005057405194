import { EntryContent } from "@/app/types/EntryContent.type";

export const parseLiveBlogVideoDTO = (data: EntryContent): any => {    
	const parsedData = {
		videos: [{
			cmsid: data?.cmsId,
			description: data?.summary,
			duration: data?.duration,
			path: data?.path,
			image: {
				imageUrl: data?.thumbnail?.imageUrl?.webp,
			},
		}],
	};
	return parsedData;
};
