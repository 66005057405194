import { gql } from "@apollo/client";

export default gql`
	query GetLiveBlogEntries(
		$getLiveBlogEntriesId: String!
		$totalItems: Int!
		$page: Int!
	) {
		getLiveBlogEntries(
			id: $getLiveBlogEntriesId
			totalItems: $totalItems
			page: $page
		) {
			meta
			eid
			author
			isPinned
			title
			body
			summary
			comment {
				title
				body
			}
			datePublished
			thumbnail {
				imageUrl {
					webp
				}
				alt
				caption
				width
				height
				imageDesktopUrl {
					webp
				}
			}
			aspectRatio
			ctNode {
				title
				type
				nid
			}
		}
	}
`;
