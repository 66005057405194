"use client";
import { useEffect, useMemo, useRef, useState } from "react";
import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import { print } from "graphql";
import getSingleContent from "@/app/queries/getSingleContent";
import { parseLiveBlogVideoDTO } from "@/app/helpers/dto/LiveBlogVideoDTO";
import { EntryContent } from "@/app/types/EntryContent.type";
import useElementOnScreen from "@/app/hooks/useElementOnScreen";
import If from "@/app/components/molecules/If/If";
import { useSocketSignal } from "@/app/hooks/useLiveBlog";
import { NotificationSnackBarSignalLive } from "@/app/components/atoms/NotificationSnackBar/NotificationSnackBar";
import DateModified from "../../atoms/Texts/Date/DateModified";
import styles from "@/app/styles/organisms/Summary.module.scss";
const Date = dynamic(() => import("@/app/components/atoms/Texts/Date/Date"));
const TitleHead = dynamic(
	() => import("@/app/components/atoms/Title/TitleHead/TitleHead")
);
const HeadEntry = dynamic(
	() => import("@/app/components/molecules/HeadEntry/HeadEntry")
);
const MediaStoryPage = dynamic(
	() => import("@/app/components/molecules/MediaStoryPage/MediaStoryPage")
);
const EntryHeader = dynamic(
	() => import("@/app/components/molecules/EntryHeader/EntryHeader")
);
const StoryPageBody = dynamic(
	() => import("@/app/components/molecules/StoryPageBody/StoryPageBody")
);
const PlayerStreaming = dynamic(
	() => import("@/app/components/molecules/PlayerStreaming/PlayerStreaming")
);
const SocialShareButtons = dynamic(
	() =>
		import("@/app/components/molecules/SocialShareButtons/SocialShareButtons")
);
const ButtonSeeMore = dynamic(
	() => import("@/app/components/atoms/Buttons/ButtonSeeMore/ButtonSeeMore")
);

interface props {
	comment?: boolean;
	idEntry?: string;
	iconLeft?: boolean;
	main?: boolean;
	pinned?: boolean;
	versionTag?: string | "lastminute" | "coverage" | "live" | "gray";
	versionUpdate?: string;
	lastUpdate?: string;
	shouldUpdateByNote?: boolean;
	content: any;
	dataLayer?: any;
	haveBanner?: boolean;
	typeBanner?: "fullImage";
	isLiveBlogLayer?: boolean;
	hasShare?: boolean;
	isModal?: boolean;
	id?: string | number;
}

const SummaryLB = ({
	comment,
	idEntry,
	main,
	versionTag,
	content,
	iconLeft,
	pinned,
	lastUpdate,
	shouldUpdateByNote,
	versionUpdate,
	dataLayer,
	haveBanner,
	typeBanner,
	isLiveBlogLayer,
	id,
	hasShare,
	isModal,
}: props) => {
	const {
		eid,
		author,
		title,
		body,
		detailBody,
		summary,
		dateModified,
		datePublished,
		isLive,
		breakingText,
		ctNode,
		path: mainPath,
		aspectRatio,
		videoStory,
	} = content ?? {};
	const [entryPath, setEntryPath] = useState<string>("");
	const [entryContent, setEntryContent] = useState<EntryContent>();
	const path = usePathname();

	const reqheaders = useMemo(() => new Headers(), []);
	reqheaders.append("Content-Type", "application/json");
	reqheaders.append(
		"X-Secure-Environment",
		`${process.env.NEXT_PUBLIC_APOLLOSEC}`
	);
	const endpoint =
		process.env.NEXT_PUBLIC_APOLLO_SERVER_URL ??
		"https://apollo.nmas.live/graphql";
	const reqOpts = useMemo(() => {
		return {
			method: "POST",
			headers: reqheaders,
			body: "",
			next: { revalidate: 5 },
		};
	}, [reqheaders]);

	useEffect(() => {
		if (ctNode) {
			ctNode.forEach((item: { type: string; nid: string }) => {
				const { type, nid } = item;

				if (type !== null && nid !== null) {
					const graphReq = JSON.stringify({
						query: print(getSingleContent),
						variables: {
							nid: parseInt(nid),
							type,
						},
					});
					reqOpts.body = graphReq;

					fetch(endpoint, reqOpts)
						.then((res) => {
							if (res.status === 200) return res.json();
							return false;
						})
						.then((data) => {
							setEntryPath(() => {
								return data?.data?.getSingleContent?.path;
							});
							setEntryContent(() => {
								return data?.data?.getSingleContent;
							});
						})
						.catch((error) => {
							return {
								data: null,
								error,
							};
						});
				}
			});
		}
	}, [ctNode, endpoint, reqOpts]);

	const typeMedia =
		content?.images?.length > 1
			? "multiImage"
			: content?.relatedVideos?.length > 1
			? "multiVideo"
			: "";

	return (
		<div
			id={eid}
			className={
				main
					? styles?.summary +
					  " " +
					  styles?.main +
					  " " +
					  (haveBanner || typeBanner ? styles?.banner : "")
					: styles?.summary
			}
		>
			{main ? (
				<>
					<div
						className={`${styles?.summaryContent} ${
							typeMedia && typeMedia === "multiImage"
								? styles["summary__multiImage"]
								: typeMedia && typeMedia === "multiVideo"
								? styles["summary__multiVideo"]
								: ""
						}`}
					>
						<HeadEntry
							textTag={
								isLive ? breakingText?.toUpperCase() : "FIN DE LA COBERTURA"
							}
							versionTag={versionTag}
							versionUpdate={versionUpdate ? versionUpdate : versionTag}
							dateModified={
								lastUpdate === "loading"
									? undefined
									: lastUpdate ?? datePublished ?? dateModified
							}
							idEntry={idEntry}
							isLive={isLive}
							typeMedia={typeMedia}
						/>

						<TitleHead textTitle={title} head="h1" typeMedia={typeMedia} />

						<div className={styles?.date}>
							<Date
								datePublished={datePublished}
								dateModified={dateModified}
								typeMedia={typeMedia}
							/>
							<span>-</span>
							<DateModified
								isText={false}
								textModify=""
								dateModified={
									lastUpdate === "loading"
										? undefined
										: lastUpdate ?? dateModified
								}
								typeMedia={typeMedia}
							/>
						</div>

						<StoryPageBody
							bodyHtml={body}
							isLiveBlog
							isMain
							isVisibleObserver={shouldUpdateByNote}
							typeMedia={typeMedia}
						/>

						<DynamicLiveBlogSummary
							content={content}
							id={id}
							videoStory={videoStory}
							typeMedia={typeMedia}
						/>

						<If condition={detailBody}>
							<div className={styles["summary__detailBody"]}>
								<StoryPageBody
									bodyHtml={detailBody}
									isLiveBlog
									isMain
									isVisibleObserver={false}
									typeMedia={typeMedia}
								/>
							</div>
						</If>

						<SocialShareButtons
							description={"summary"}
							liveBlog={true}
							link={mainPath}
						/>
					</div>
				</>
			) : pinned ? (
				<div className={styles?.summary + " " + styles?.pinned}>
					<EntryHeader
						pinned
						iconLeft={iconLeft}
						dateModified={dateModified ?? datePublished}
						path={path}
						eid={eid}
						entrypath={entryPath}
					/>
					<TitleHead
						className={styles?.pinnedTitle}
						textTitle={content?.title ?? entryContent?.title}
					/>
					{ctNode ? (
						<>
							{entryContent?.duration ? (
								<MediaStoryPage
									cardItem={parseLiveBlogVideoDTO(entryContent)}
									isOpening
									noLowerDescription
									isLiveBlog
									isVertical={aspectRatio === "9/16"}
									dataLayer={{
										...dataLayer,
										item_list_id: "live blog",
										item_list_name: "liveBlog",
										isLiveBlog: true,
									}}
									isPinned={true}
								/>
							) : (
								<MediaStoryPage
									cardItem={entryContent}
									isLiveBlog
									isVertical={aspectRatio === "9/16"}
									isPinned={true}
								/>
							)}
						</>
					) : null}
					{(summary || entryContent?.summary) && (
						<>
							<StoryPageBody
								bodyHtml={summary ?? `<p>${entryContent?.summary}</p>`}
								isLiveBlog
								isSummary
								isVisibleObserver={shouldUpdateByNote}
								isPinned={true}
							/>
							{ctNode?.[0]?.type === "news" && (
								<ButtonSeeMore
									type="liveblog"
									textBtn={`Aquí la Nota Completa`}
									path={entryPath}
									dataLayer={dataLayer}
									isPinned={true}
								/>
							)}
						</>
					)}
					{body && (
						<StoryPageBody
							bodyHtml={body}
							isLiveBlog
							isVisibleObserver={shouldUpdateByNote}
							dataLayer={dataLayer}
							isPinned={true}
						/>
					)}
				</div>
			) : (
				<div
					className={`${(isModal && styles?.modal) || ""}
            ${
							comment
								? styles?.summaryContent + " " + styles?.comment
								: styles?.summaryContent
						}
          `}
				>
					<EntryHeader
						dateModified={dateModified ?? datePublished}
						path={path ?? ""}
						eid={eid}
						entrypath={entryPath}
						iscomment={comment === true ? true : false}
						author={author}
						isLiveBlogLayer={isLiveBlogLayer}
						dataLayer={dataLayer}
						hasShare={hasShare}
					/>
					<TitleHead
						secondTitle
						textTitle={content?.title ?? entryContent?.title}
					/>

					{ctNode ? (
						<>
							{entryContent?.duration ? (
								<MediaStoryPage
									cardItem={parseLiveBlogVideoDTO(entryContent)}
									isOpening
									noLowerDescription
									isLiveBlog
									isVertical={aspectRatio === "9/16"}
									dataLayer={{
										...dataLayer,
										item_list_id: "live blog",
										item_list_name: "liveBlog",
										isLiveBlog: true,
									}}
								/>
							) : (
								<MediaStoryPage
									cardItem={entryContent}
									isLiveBlog
									isVertical={aspectRatio === "9/16"}
								/>
							)}
						</>
					) : null}

					{(summary || entryContent?.summary) && (
						<>
							<StoryPageBody
								bodyHtml={summary ?? `<p>${entryContent?.summary}</p>`}
								isLiveBlog
								isSummary
								isVisibleObserver={shouldUpdateByNote}
							/>
							{ctNode?.[0]?.type === "news" && (
								<ButtonSeeMore
									type="liveblog"
									textBtn={`Aquí la Nota Completa`}
									path={entryPath}
									dataLayer={{
										...dataLayer,
										content_name: dataLayer?.entry?.ctNode?.[0]?.title
											? dataLayer?.entry?.ctNode?.[0]?.title
											: dataLayer?.title,
									}}
								/>
							)}
						</>
					)}
					{body && (
						<StoryPageBody
							bodyHtml={body}
							isLiveBlog
							isVisibleObserver={shouldUpdateByNote}
							dataLayer={dataLayer}
						/>
					)}
				</div>
			)}
		</div>
	);
};

function DynamicLiveBlogSummary({ content, id, videoStory, typeMedia }: any) {
	const scrollObserverRef = useRef<any>(null);

	const defaultPreview = {
		uiLive: content?.liveChannel?.ui,
		pathLive: content?.liveChannel?.path,
		nameLive: content?.liveChannel?.name,
		codeLive: content?.liveChannel?.uid,
	};

	const streamingRef = useRef(null);
	const { signal, updateSignal, handlePrewiewUpdates } = useSocketSignal({
		id,
		defaultPreview,
	});

	const { aspectRatio, thumbnail = {} } = content ?? {};

	const hasImages =
		thumbnail && thumbnail.imageUrl
			? Object.values(thumbnail.imageUrl).every((value) => value === null)
			: false;

	const {
		uiLive = null,
		pathLive = null,
		nameLive = null,
		codeLive = null,
	} = signal ?? {};

	const aspectRatioClass = `
  ${
		aspectRatio === "4:3"
			? styles["summary__liveblog--4_3"]
			: aspectRatio === "1:1"
			? styles["summary__liveblog--1_1"]
			: aspectRatio === "3:4"
			? styles["summary__liveblog--3_4"]
			: ""
	}`;

	const hasLive = codeLive !== null && codeLive !== undefined;
	const isYTLive = nameLive === "Youtube";
	const hasVideo = videoStory?.cmsid;

	const { isVisible } = useElementOnScreen(scrollObserverRef, {
		root: null,
		rootMargin: "0px",
		threshold: 0.2,
	});

	const scrollToUpdates = () => {
		if (updateSignal) {
			// Realizar scroll hacia el elemento ScrollObserver
			const scrollObserverPos =
				scrollObserverRef.current.getBoundingClientRect().top;

			// Realizar scroll hacia el elemento ScrollObserver con un offset de 56 píxeles
			if (scrollObserverRef.current) {
				window.scrollTo({
					top: window.scrollY + scrollObserverPos - 100,
					behavior: "smooth",
				});
			}
			handlePrewiewUpdates();
		}
	};

	return (
		<div ref={scrollObserverRef}>
			{!hasLive && (!hasImages || hasVideo) && (
				<div className={`${styles?.media} ${aspectRatioClass}`}>
					<MediaStoryPage
						cardItem={content}
						isLiveBlog
						showPagination
						showZoomOption
						isAlternativeVideos
						showNavigation={false}
						aspect={aspectRatio}
						isVertical={aspectRatio === "9/16"}
						carouselMedia={typeMedia}
					/>
				</div>
			)}

			{hasLive && !isYTLive && (
				<div ref={streamingRef}>
					<PlayerStreaming
						ui={uiLive}
						path={pathLive}
						uid={codeLive}
						isLiveBlog
					/>
				</div>
			)}
			{hasLive && isYTLive && (
				<iframe
					src={`https://www.youtube.com/embed/${codeLive}?autoplay=1&mute=1`}
					allowFullScreen
					className="live_liveBlog"
				/>
			)}

			<NotificationSnackBarSignalLive
				hasUpdated={!!updateSignal}
				outVisibility={isVisible}
				onClick={() => scrollToUpdates()}
			/>
		</div>
	);
}

export default SummaryLB;
