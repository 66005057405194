import Skeleton from "react-loading-skeleton";
import styles from "@/app/styles/organisms/Summary.module.scss";

interface Props {
	isLoading?: boolean;
	quantity?: number;
}

const SummarySkeleton = ({
	isLoading = false,
	quantity = 1,
}: Props) => {
	const renderSkeletons = () => {
		const skeletons = [];
		for (let i = 0; i < quantity; i++) {
			skeletons.push(
        <article className={styles?.summary} key={i}>
          <div className={styles?.summaryContent}>
            <div key={i} style={{ marginBottom: i < quantity - 1 ? "10px" : 0 }}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <Skeleton
                  circle
                  height={40}
                  width={40}
                  style={{ marginRight: "10px" }}
                />
                <Skeleton height={20} width={240} />
              </div>
              <Skeleton height={300} />
              <Skeleton count={3} height={20} style={{ marginTop: "10px" }} />
            </div>
          </div>
        </article>
			);
		}
		return skeletons;
	};

	return (isLoading && (renderSkeletons()));
};

export default SummarySkeleton;
