"use client";
import { useState } from "react";
import dynamic from "next/dynamic";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	EmailShareButton,
} from "react-share";
import style from "@/app/styles/Molecules/ButtonShareSocial.module.scss";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { usePathname } from "next/navigation";
import handleSelectContent from "@/app/helpers/analytics/handleSelectcontent";

const Icon = dynamic(() => import("@/app/components/atoms/Icon/Icon"));
const BaseSnackbar = dynamic(
	() => import("@/app/components/molecules/Snackbar/BaseSnackbar")
);

const VARIANTS = {
	facebook: "Facebook",
	twitter: "Twitter",
	threads: "Threads",
	whatsapp: "Whatsapp",
	copy: "Copy",
	email: "Email",
};

type ButtonShareSocialType = {
	variant?:
		| "Facebook"
		| "Twitter"
		| "Whatsapp"
		| "Copy"
		| "Email"
		| "Threads"
		| undefined;
	shareUrl?: string;
	shareTitle?: string;
	shareDescription?: string;
	isShowName?: boolean;
	customName?: string;
	isVariantStoryPage?: boolean;
	isLiveBlogLayer?: boolean;
	isBordered?: boolean;
	dataLayer?: any;
};
// Este es el componente que se usa para los botones de share 231130
export default function ButtonShareSocial({
	variant = undefined,
	shareTitle = "Titulo",
	shareDescription = "Descripción",
	shareUrl = process?.env?.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "") ||
		"https://www.nmas.com.mx",
	isShowName = false,
	customName = undefined,
	isLiveBlogLayer,
	isBordered,
	dataLayer,
}: ButtonShareSocialType) {
	const [isOpen, setIsOpen] = useState(false);
	const path = usePathname();
	const BASE_URL =
		process?.env?.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "") ||
		"https://www.nmas.com.mx/";

	const reParams = /\?([a-z-]+)=([a-z-0-9]+)$/; // Sirve para saber si la url tiene o no parámetros en la url
	shareUrl = shareUrl?.includes(BASE_URL)
		? `${reParams.test(shareUrl) ? shareUrl : shareUrl + "/"}`
		: `${BASE_URL}${reParams.test(shareUrl) ? shareUrl : shareUrl + "/"}`;
	shareUrl = shareUrl?.replace(/[/]+$/, "/");
	const re = /\/videos\//;

	const { height, width } = useWindowDimensions();

	const eventData = {
		event: "select_content",
		clickBloque: undefined,
		content_name: `${re.test(shareUrl) ? "VOD" : "NEW"}>${shareUrl?.replace(
			BASE_URL,
			""
		)}`,
		colorBloque: "Light",
		contenidosBloque: undefined,
		content_type: "Comparte",
		countWidget: undefined,
		descripcionBloque: undefined,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: undefined,
		section: `${path}`,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: undefined,
		tipoElemento: undefined,
		tituloBloque: undefined,
	};
	const cleanTitle = dataLayer?.entry?.ctNode?.[0]?.title
		? dataLayer?.entry?.ctNode?.[0]?.title?.replaceAll('"', "")?.trim()
		: dataLayer?.entry?.title;

	const copyToClipboard = () => {
		setIsOpen(true);
		setTimeout(() => {
			setIsOpen(false);
		}, 2000);
		if (isLiveBlogLayer) {
			handleSelectContent(
				{
					event: "select_content",
					eventAction: "click",
					content_type: "CopyLinkLiveBlog",
					content_name: cleanTitle,
					colorBloque: undefined,
				},
				undefined,
				dataLayer?.section.replace(BASE_URL, "/") || ""
			);
		} else {
			handleDataLayerOnClick(
				{
					...eventData,
					content_name: "CopyLink",
					tipoElemento: "CopyLink",
					content_type: "CopyLink",
				},
				1,
				shareUrl?.replace(BASE_URL, ""),
				true
			);
		}
	};

	return (
		<>
			{variant === VARIANTS.facebook && (
				<div className={`${style["buttonShare__wrapper"]}`}>
					<FacebookShareButton
						url={shareUrl}
						title={"Compartir por " + variant}
						onClick={() => {
							if (isLiveBlogLayer) {
								handleSelectContent(
									{
										event: "select_content",
										eventAction: "click",
										content_type: "Comparte",
										content_name: cleanTitle,
										colorBloque: undefined,
									},
									undefined,
									shareUrl.replace(BASE_URL, "") || ""
								);
							} else {
								handleDataLayerOnClick(
									{
										...eventData,
										content_name: "Facebook",
										tipoElemento: "Facebook",
									},
									1,
									path,
									true
								);
							}
						}}
						aria-label={"Compartir por " + variant}
					>
						<Icon
							className={`${style["buttonShare__icons"]}`}
							name="Facebook"
						/>
					</FacebookShareButton>
					{isShowName && (
						<p className={`${style["buttonShare__nameWrapper"]}`}>
							{customName ? customName : variant}
						</p>
					)}
				</div>
			)}
			{variant === VARIANTS.twitter && (
				<div className={`${style["buttonShare__wrapper"]}` + " twitter"}>
					<TwitterShareButton
						url={shareUrl}
						title={`${shareTitle}\n`}
						via={"nmas"}
						windowWidth={width}
						windowHeight={height}
						onClick={() => {
							if (isLiveBlogLayer) {
								handleSelectContent(
									{
										event: "select_content",
										eventAction: "click",
										content_type: "Comparte",
										content_name: cleanTitle,
										colorBloque: undefined,
									},
									undefined,
									shareUrl.replace(BASE_URL, "") || ""
								);
							} else {
								handleDataLayerOnClick(
									{
										...eventData,
										content_name: "Twitter",
										tipoElemento: "Twitter",
									},
									1,
									path,
									true
								);
							}
						}}
						aria-label={"Compartir por " + variant}
					>
						<Icon
							className={`${style["buttonShare__icons"]} ${style["buttonShare__icons--twitter"]}`}
							name="Twitter"
						/>
						{isShowName && (
							<p className={`${style["buttonShare__nameWrapper"]}`}>
								{customName ? customName : variant}
							</p>
						)}
					</TwitterShareButton>
				</div>
			)}
			{variant === VARIANTS.threads && (
				<div className={`${style["buttonShare__wrapper"]}` + " threads"}>
					<button
						// url={shareUrl}
						// title={`${shareTitle}\n`}
						// via={"nmas"}
						// windowWidth={width}
						// windowHeight={height}
						className={`${style["buttonShare__button"]}`}
						onClick={() => {
							if (isLiveBlogLayer) {
								handleSelectContent(
									{
										event: "select_content",
										eventAction: "click",
										content_type: "Comparte",
										content_name: cleanTitle,
										colorBloque: undefined,
									},
									undefined,
									shareUrl.replace(BASE_URL, "") || ""
								);
							} else {
								handleDataLayerOnClick(
									{
										...eventData,
										content_name: "Threads",
										tipoElemento: "Threads",
									},
									1,
									path,
									true
								);
							}
							window.open(
								`https://www.threads.net/intent/post?text=${
									shareTitle || ""
								} ${shareUrl}`
							);
						}}
						aria-label={"Compartir por " + variant}
					>
						<Icon
							className={`${style["buttonShare__icons__threads"]} ${style["buttonShare__icons"]} `}
							name="threads"
						/>
						{isShowName && (
							<p className={`${style["buttonShare__nameWrapper"]}`}>
								{customName ? customName : variant}
							</p>
						)}
					</button>
				</div>
			)}
			{variant === VARIANTS.whatsapp && (
				<div className={`${style["buttonShare__wrapper"]}`}>
					<WhatsappShareButton
						url={shareUrl}
						separator=" - "
						onClick={() => {
							if (isLiveBlogLayer) {
								handleSelectContent(
									{
										event: "select_content",
										eventAction: "click",
										content_type: "Comparte",
										content_name: cleanTitle,
										colorBloque: undefined,
									},
									undefined,
									shareUrl.replace(BASE_URL, "") || ""
								);
							} else {
								handleDataLayerOnClick(
									{
										...eventData,
										content_name: "Whatsapp",
										tipoElemento: "Whatsapp",
									},
									1,
									shareUrl?.replace(BASE_URL, ""),
									true
								);
							}
						}}
						aria-label={"Compartir por " + variant}
					>
						<Icon
							className={`${style["buttonShare__icons"]}`}
							name="WhatsApp"
						/>
						{isShowName && (
							<p className={`${style["buttonShare__nameWrapper"]}`}>
								{customName ? customName : variant}
							</p>
						)}
					</WhatsappShareButton>
				</div>
			)}
			{variant === VARIANTS.copy && (
				<>
					<div
						className={`${
							style["buttonShare__wrapper"] +
							" " +
							(isBordered ? style.isLiveblog : "")
						}`}
					>
						<CopyToClipboard text={shareUrl || "/"} onCopy={copyToClipboard}>
							<button
								type="button"
								className={`${style["buttonShare__buttonWrapper"]}`}
								aria-label="Copiar enlace"
							>
								<Icon
									className={`${style["buttonShare__icons"]}`}
									name="chain"
								/>
							</button>
						</CopyToClipboard>
						{isShowName && (
							<p className={`${style["buttonShare__nameWrapper"]}`}>
								{customName ? customName : variant}
							</p>
						)}
					</div>
					<BaseSnackbar
						label="¡Enlace copiado al portapapeles!"
						isOpenState={isOpen}
					/>
				</>
			)}
			{variant === VARIANTS.email && (
				<div className={`${style["buttonShare__wrapper"]}`}>
					<EmailShareButton
						url={shareUrl}
						subject={shareTitle}
						body={shareDescription}
					>
						<Icon className={`${style["buttonShare__icons"]}`} name="email" />
					</EmailShareButton>
					{isShowName && (
						<p className={`${style["buttonShare__nameWrapper"]}`}>
							{customName ? customName : variant}
						</p>
					)}
				</div>
			)}
		</>
	);
}
