import { useEffect, useRef, useState } from "react";

export default function useElementOnScreen(
	ref: any,
	options: any,
	shouldActive = true
) {
	const [isVisible, setIntersecting] = useState(false);

	const callbackFunction = (entries: IntersectionObserverEntry[]) => {
		const [entry] = entries;
		if (!entry.isIntersecting || shouldActive) {
			setIntersecting(entry.isIntersecting);
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFunction, options);
		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				observer.unobserve(ref.current);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref, options]);

	return { ref, isVisible };
}
