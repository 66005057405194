import Icon from "@/app/components/atoms/Icon/Icon";
import { PlayerIconProps, IconsParams } from "./types/PlayerIcon"
import style from "@/app/styles/Molecules/PlayerIcon.module.scss";

const PlauerIcon = ({ isTapToPlay = false, callback = null }: PlayerIconProps) => {
	const iconParams: IconsParams = { name: isTapToPlay ? "newPlayer" : "newPlayerLink" };

	if (isTapToPlay) iconParams.onClick = callback;

	return (
		<div className={`${style.playerIcon}`}>
			<Icon {...iconParams} />
		</div>
	);
};

export default PlauerIcon;
