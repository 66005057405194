import { useCallback, useEffect, useState } from "react";

function getPrevElem(list:any){
  const sibling = list[0].previousElementSibling;
  if(sibling instanceof HTMLElement){
    return sibling;
  }
  return null;
}

function getNextElem(list:any){
  const sibling = list[list.length - 1].nextElementSibling;
  if(sibling instanceof HTMLElement){
    return sibling;
  }
  return null;
}

export function usePosition(ref: { current: HTMLElement | null; }){
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if(ref.current){
      const element = ref.current;

      const update = () => {
        const rect = element.getBoundingClientRect()
        const visibleItems = Array.from(element.children).filter((child) => {
          const childRect = child.getBoundingClientRect();
          return rect.left <= childRect.left && rect.right >= childRect.right;
        });
  
        if(visibleItems.length > 0){
          setPrevEl(getPrevElem(visibleItems));
          setNextEl(getNextElem(visibleItems));
        }
      }
      update();

      element.addEventListener("scroll", update, {passive: true});

      return () => {
        element.removeEventListener("scroll", update);
      }
    }
  }, [ref]);

  const scrollToEl = useCallback((element: HTMLElement) => {
    const currentNode = ref.current;
    if(!currentNode || !element) return;

    const newScrollPos = element.offsetLeft + element.getBoundingClientRect().width / 2 - currentNode.getBoundingClientRect().width / 2;

    currentNode.scroll({
      left: newScrollPos, 
      behavior: "smooth"
    })

  }, [ref]);

  const scrollRight = useCallback( () => {
    if(nextEl !== null){
      scrollToEl(nextEl)
    }
  }, [nextEl, scrollToEl]);

  const scrollLeft = useCallback(() => {
    if(prevEl !== null){
      scrollToEl(prevEl);
    }
  }, [prevEl, scrollToEl]);


  return {
    hasItemL: prevEl !== null,
    hasItemR:  nextEl !== null,
    scrollLeft,
    scrollRight
  }
}