"use client";
import { useContext, useEffect } from "react";
import Image from "next/image";
import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import { CountDownContext } from "@/app/context/CardCountContext";
import styles from "@/app/styles/Cards/EndCard.module.scss";

export default function EndCard({
	actualVod,
	onEndedVideo,
	videoList,
	showEndCard,
	setShowEndCard,
}: any) {
	const index = videoList?.findIndex((video: any, i: number) =>
		video?.title === actualVod?.title ? i : 0
	);
	const initialCountDown = 5;
	// const [countDown, setCountDown] = useState(initialCountDown);
	let interval: any;
	const next =
		(index === -1 ? videoList?.[index + 2] : videoList?.[index + 1]) ??
		(index === videoList?.length - 1 ? videoList?.[0] : {});
	const fileDuration = next?.duration;
	const date = new Date();
	date?.setSeconds(fileDuration ?? 1);
	const time = date?.toISOString()?.substr(11, 8);
	const { countDown, setCountDown } = useContext(CountDownContext);
	useEffect(() => {
		if (showEndCard) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			interval = setInterval(() => {
				setCountDown(countDown - 1);
				if (countDown === 0) {
					clearInterval(interval);
					onEndedVideo();
					setCountDown(initialCountDown);
				}
			}, 1000);
		}
		return () => {
			clearInterval(interval);
		};
	}, [countDown, showEndCard]);
	const handleClose = () => {
		if (typeof setShowEndCard === "undefined") return;
		setShowEndCard(false);
	};

	return (
		<div className={styles.endcard}>
			<div className={styles.endcard__wrapper}>
				<div className={styles.endcard__body}>
					<div className={styles.endcard__wrapper_image}>
						<Image
							src={
								(next?.thumbnail?.imageUrl?.webp || next?.image?.imageUrl) ??
								"/assets/placeholder_.svg"
							}
							alt={next?.alt ?? next?.title}
							style={{ objectFit: "cover" }}
							loading="lazy"
							placeholder="blur"
							blurDataURL={`${
								process.env.NEXT_PUBLIC_BLURIMAGE ||
								"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
							}`}
							width={368}
							height={207}
						/>
					</div>
					<div className={styles.endcard__description}>
						<div className={styles.endcard__description__container}>
							<h2 className={styles.endcard__description__container__title}>
								{next?.title}
							</h2>
							<span className={styles.endcard__duration}>
								{secondsToMinutes(next?.duration)}
							</span>
						</div>
						<div className={styles.endcard__wrapper_buttons}>
							<button
								className={styles.endcard__wrapper_buttons__action}
								onClick={onEndedVideo}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									height="24"
									viewBox="0 0 24 24"
									width="24"
									fill="#FFFFFF"
								>
									<path d="M0 0h24v24H0z" fill="none" />
									<path d="M8 5v14l11-7z" />
								</svg>
								<span>Reproducir</span>
							</button>
							<button
								className={styles.endcard__wrapper_buttons__action_variant}
								onClick={() => {
									clearInterval(interval);
									setCountDown(initialCountDown);
									handleClose();
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									height="24"
									width="24"
									fill="#FFFFFF"
								>
									<path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" />
								</svg>
								<span>Cancelar</span>
							</button>
						</div>
					</div>
				</div>
				<p className={styles.endcard__coutdown}>
					A continuación en {countDown} segundos
				</p>
			</div>
		</div>
	);
}
