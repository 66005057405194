const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const toCDMX = (date) => {
	const utcTime = dayjs(date).tz('America/Mexico_city');
	return utcTime;
};

export default toCDMX;
