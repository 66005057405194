"use client";
import { useState } from "react";
import handleSelectContent from "@/app/helpers/analytics/handleSelectcontent";
import styles from "@/app/styles/Molecules/NewsletterPolitics.module.scss";

export default function NewsletterPolitics() {
	const [isSubscribed, setIsSubscribed] = useState(false);

	const handleChange = () => {
		setIsSubscribed((current) => !current);
	};

	const sendDataLayer = () => {
		handleSelectContent(
			{
				event: "select_content",
				eventAction: "subscribe",
				content_type: "NewsletterForm_politica",
				content_name: "Completed",
				colorBloque: undefined,
			},
			undefined,
			window.location.href
		);
	};

	return (
		<div>
			<section className={`${styles["newsletterHtml__wrapper"]}`}>
				<article className={`${styles["newsletterHtml__wrapperText"]}`}>
					<div id="mc_embed_shell">
						<div id="mc_embed_signup">
							<form
								action="https://nmas.us1.list-manage.com/subscribe/post?u=39397b35b96bb5653b3c35da2&amp;id=efaa737a46&amp;f_id=00f679e2f0"
								className={styles?.validate}
								id="mc-embedded-subscribe-form"
								method="post"
								name="mc-embedded-subscribe-form"
								target="_self"
							>
								<div id="mc_embed_signup_scroll">
									<div className={styles?.mc_firstseparator}>
										<></>
									</div>
									<div className={styles?.mc_margin}>
										<div className={styles?.mc_field_input}>
											<input
												id="mce-FNAME"
												name="FNAME"
												placeholder="Nombre(s)"
												type="text"
											/>
										</div>
										<div className={styles?.mc_field_input}>
											<input
												id="mce-LNAME"
												name="LNAME"
												placeholder="Apellidos"
												type="text"
											/>
										</div>
										<div className={styles?.mc_field_input}>
											<input
												id="mce-EMAIL"
												name="EMAIL"
												placeholder="Correo electrónico"
												type="email"
											/>
										</div>
									</div>
									<div className={styles?.mc_separator}>
										<></>
									</div>
									<div className={styles?.mc_margin}>
										<div className={styles?.mc_field_group}>
											<strong>
												Selecciona tus intereses para una mejor experiencia
											</strong>
										</div>
										<ul className={styles?.mc_checkboxes}>
											<li>
												<input
													id="mce-group[387401]-387401-6"
													name="group[387401][2]"
													type="checkbox"
												/>
												<label htmlFor="mce-group[387401]-387401-6">
													Economía
												</label>
											</li>
											<li>
												<input
													id="mce-group[387401]-387401-0"
													name="group[387401][1]"
													type="checkbox"
												/>
												<label htmlFor="mce-group[387401]-387401-0">
													Noticias Locales
												</label>
											</li>
											<li>
												<input
													id="mce-group[387401]-387401-2"
													name="group[387401][8]"
													type="checkbox"
												/>
												<label htmlFor="mce-group[387401]-387401-2">
													Entretenimiento
												</label>
											</li>
											<li>
												<input
													id="mce-group[387401]-387401-7"
													name="group[387401][128]"
													type="checkbox"
												/>
												<label htmlFor="mce-group[387401]-387401-7">
													Deporte
												</label>
											</li>
											<li>
												<input
													id="mce-group[387401]-387401-3"
													name="group[387401][16]"
													type="checkbox"
												/>
												<label htmlFor="mce-group[387401]-387401-3">
													Tendencias
												</label>
											</li>
										</ul>
									</div>
									<div className={styles?.mc_separator}>
										<></>
									</div>
									<div className={styles?.mc_margin}>
										<ul className={styles?.mc_checkboxes}>
											<li className={styles?.mc_minitext}>
												<input
													type="checkbox"
													name="privacidad"
													id="privacidad"
													defaultValue={`${isSubscribed}`}
													onChange={handleChange}
												/>
												<label htmlFor="privacidad">
													* Acepto Políticas de Privacidad.
												</label>{" "}
												<a
													href="https://www.nmas.com.mx/politicas-de-privacidad/"
													target="_blank"
													rel="noreferrer"
												>
													Consúltalas aquí
												</a>
												.
											</li>
											<li className={styles?.mc_minitext}>
												<input
													type="checkbox"
													name="group[387417][512]"
													id="mce-group[387417]-387417-1"
												/>
												<label htmlFor="mce-group[387417]-387417-1">
													Acepto que me manden comunicación relacionada al
													Portal{" "}
													<a
														href="https://www.nmas.com.mx/"
														target="_blank"
														rel="noreferrer"
													>
														Home
													</a>
												</label>
											</li>
											<li className={styles?.mc_minitext}>
												<input
													type="checkbox"
													name="group[387417][256]"
													id="mce-group[387417]-387417-0"
													defaultChecked={true}
												/>
												<label htmlFor="mce-group[387417]-387417-0">
													Acepto suscribirme al Newsletter de POLÍTICA N+
												</label>
											</li>
										</ul>
										<br />
									</div>
									<div className={styles?.mc_margin}>
										<div className={styles?.clear} id="mce-responses">
											<div className={styles?.response} id="mce-error-response">
												<></>
											</div>
											<div
												className={styles?.response}
												id="mce-success-response"
											>
												<></>
											</div>
										</div>
										<div aria-hidden="true">
											<input
												name="b_39397b35b96bb5653b3c35da2_efaa737a46"
												type="hidden"
											/>
										</div>
										<div className={styles?.clear}>
											<input
												className={styles?.mc_embedded_subscribe}
												id="mc-embedded-subscribe"
												name="subscribe"
												type="submit"
												defaultValue="Suscribirme"
												disabled={!isSubscribed}
												onClick={sendDataLayer}
											/>
										</div>
										<div>
											<br />
											<p className={styles?.mini_more}>
												* Debes aceptar nuestras políticas de privacidad para
												poder registrarte y seleccionar las suscripciones que
												deseas para enviar el formulario
											</p>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</article>
			</section>
		</div>
	);
}
