"use client";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { SimpleLinkType } from "@/app/types/SimpleLinkType";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import Link from "next/link";
import { usePathname } from "next/navigation";

declare const window: WindowWithDataLayer;

export default function LinkLoadMore({
	link,
	blockData,
	cardPosition,
}: SimpleLinkType) {
	const pathName = usePathname();
	blockData.tipoElemento = `LinkText>${link?.title}`;

	return (
		<Link
			href={link?.url}
			title={link?.title}
			prefetch={false}
			onClick={() => handleDataLayerOnClick(blockData, cardPosition, pathName)}
		>
			{link?.title}
		</Link>
	);
}
