

import { notFound } from "next/navigation";

import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import getChannelGrids from "@/app/queries/getChannelGrids";

import { programas } from "@/data";

export const getChannelsEnVivo = async () => {
	const channelsId = programas.data.getProgrammingGrid.channels.map(
		(channel) => {
			return channel.codigo_canal;
		}
	);
	const { data, ...rest } = await fetchApolloData(getChannelGrids, {
		params: channelsId,
	});

	const channelResponse =
		programas?.data?.getProgrammingGrid?.channels?.map((channel: any) => {
			const channelData = data?.getChannelsGrids?.find(
				(data: any) => data?.codigo_canal === channel?.codigo_canal
			);
			return {
				...channel,
				shows: channelData?.PROGRAMACION?.CANAL?.SHOWS ?? "",
			};
		}) ?? [];

	if (rest?.errors) {
		console.error("ERROR <<<");
		if (rest?.errors instanceof Array) {
			rest?.errors?.forEach((error: any) => {
				switch (error?.extensions?.code) {
					case "INTERNAL_SERVER_ERROR":
						if (
							error?.message === "All promises were rejected" ||
							error?.message === "Invalid response"
						) {
							console.error(error?.extensions);
							notFound();
						}
						break;
					case 502:
						throw new Error(error?.message);
					case 404:
						notFound();
				}
			});
		} else {
			throw new Error(`${rest?.errors?.message} - [ERR]`);
		}
	}

	return channelResponse;
};
