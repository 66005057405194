import { gql } from "@apollo/client";

export default gql`
  query GetSingleContent($nid: Int!, $type: String!) {
    getSingleContent(nid: $nid, type: $type, ) {
      summary
      cmsId
      duration
      title,
      path,
      thumbnail {
        alt
        caption
        width
        height
        imageUrl {
          webp
        }
      }
    }
  }
`;