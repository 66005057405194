"use client";
import { useEffect } from "react";

export function LBPathUpdater() {
	const hash = window.location.hash;
	useEffect(() => {
		const post = document.getElementById(
			hash.replace("#", "").replace("/", "")
		);
		let to: any;
		if (post) {
			to = setTimeout(() => {
				window.scrollTo({
					top: post?.offsetTop ?? 0,
					behavior: "smooth",
				});
			}, 500);
		}
		return () => clearTimeout(to);
	}, [hash]);
	return <></>;
}
