import { gql } from "@apollo/client";

export default gql`
	query GetEnVivo($path: String!) {
		getEnVivo(path: $path) {
			id
			adsByBlock
			author
			contentType
			pagePath
			quitarPauta
			terms
			title
			type
			embeds {
				embed
				count
			}
			field_block_custom {
				data {
					id
					position
					type
					total
				}
			}
			metaTags {
				title
				description
			}
		}
	}
`;
