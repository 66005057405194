const {setConfig, lists} = require("@mailchimp/mailchimp_marketing");

export const sendNewsletterForm = async (email: string) => {
  // console.log("FORM SENT");

  setConfig({
    apiKey: process.env.NEXT_PUBLIC_MAILCHIMP_API_KEY,
		server: "us1",
  });

  try {
		const r = await lists.addListMember(
			process.env.NEXT_PUBLIC_MAILCHIMP_LIST_KEY,
			{
				email,
				status: "pending"
			}
		);
    // console.log("LLEGÓ");
		return { success: true, r };
	} catch (err) {
		return { error: true, err };
	}


	// return await fetch("/api/suscribe", {
	// 	method: "POST",
	// 	headers: {
	// 		"Content-Type": "application/json",
	// 	},
	// 	body: JSON.stringify({
	// 		email_address: email,
	// 		status: "pending",
	// 	}),
	// });

};