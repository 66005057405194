"use client";
import { useEffect, useRef } from "react";

interface ParseHTMLJSProps {
	data: string;
}

const ParseHTMLJS = ({ data }: ParseHTMLJSProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const scriptsLoaded = useRef<Set<string>>(new Set());

	const loadScript = (src: string): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (scriptsLoaded.current.has(src)) {
				resolve();
				return;
			}

			const script = document.createElement("script");
			script.src = src;
			script.async = true;
			script.onload = () => {
				scriptsLoaded.current.add(src);
				resolve();
			};
			script.onerror = reject;
			document.body.appendChild(script);
		});
	};

	const waitForContainer = (containerId: string): Promise<void> => {
		return new Promise((resolve) => {
			const checkContainer = () => {
				const container = document.getElementById(containerId);
				if (container) {
					resolve();
				} else {
					setTimeout(checkContainer, 100); // Revisar cada 100ms
				}
			};
			checkContainer();
		});
	};

	useEffect(() => {
		const initializeCharts = async () => {
			try {
				// Primero renderizamos el HTML
				if (containerRef.current) {
					containerRef.current.innerHTML = data;
				}

				// Encontrar todos los scripts de Highcharts
				const tempDiv = document.createElement("div");
				tempDiv.innerHTML = data;

				// Cargar scripts externos
				const externalScripts = Array.from(
					tempDiv.querySelectorAll("script[src]")
				);
				const highchartsScripts = externalScripts
					.map((script) => script.getAttribute("src"))
					.filter((src): src is string => src !== null);

				// Cargar los scripts de Highcharts en orden
				for (const src of highchartsScripts) {
					await loadScript(src);
				}

				// Encontrar los scripts inline y extraer los IDs de los contenedores
				const inlineScripts = Array.from(
					tempDiv.querySelectorAll("script:not([src])")
				);

				for (const script of inlineScripts) {
					if (script.textContent) {
						const scriptContent = script.textContent.replace(
							/\/\/\s*<!\[CDATA\[([\s\S]*?)\/\/\s*\]\]>/,
							"$1"
						);

						// Extraer el ID del contenedor del script
						const containerIdMatch = scriptContent.match(
							/Highcharts\.chart\(['"]([^'"]+)['"]/
						);

						if (containerIdMatch && containerIdMatch[1]) {
							const containerId = containerIdMatch[1];

							// Esperar a que el contenedor exista
							await waitForContainer(containerId);

							// Crear una función que ejecute el script en el contexto global
							// eslint-disable-next-line no-new-func
							const executeScript = new Function(scriptContent);
							executeScript();
						}
					}
				}
			} catch (error) {
				console.error("Error initializing Highcharts:", error);
			}
		};

		if (data && data.includes("container-highcharts")) {
			initializeCharts();
		} else {
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = data;

			const scripts = tempDiv.querySelectorAll("script");

			scripts.forEach((script) => {
				const newScript = document.createElement("script");

				if (script.src) {
					newScript.src = script.src;
					newScript.async = true;
				} else {
					newScript.textContent = script.textContent;
				}
				document?.body?.appendChild(newScript);
			});
		}
	}, [data]);

	return (
		<>
			{data.includes("container-highcharts") ? (
				<div ref={containerRef} />
			) : (
				<div dangerouslySetInnerHTML={{ __html: data }} />
			)}
		</>
	);
};

export default ParseHTMLJS;
