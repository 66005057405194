import styles from "@/app/styles/Molecules/StoryPlayerLightbox.module.scss";
import Script from "next/script";

export default function StoryPlayerLighbox({ stories, closeDialog }: any) {
	return (
		<section className={styles?.lb_container}>
			<Script async src="https://cdn.ampproject.org/v0.js" />
			<Script
				async
				src="https://cdn.ampproject.org/v0/amp-story-player-0.1.js"
				custom-element="amp-story-player"
			/>
			<button
				role="button"
				onClick={() => closeDialog()}
				className={styles?.lb_btn_close}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					height="24"
					width="24"
					fill="#ffffff"
				>
					<path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" />
				</svg>
			</button>
			<amp-story-player layout="fixed" width="360" height="600">
				{stories?.map((story: any) => {
					return (
						<a key={story?.storyID} href={story?.url}>
							<img
								src={story?.storyImg}
								width="360"
								alt="Web story"
								height="600"
								loading="lazy"
								data-amp-story-player-poster-img
							/>
							{story?.title}
						</a>
					);
				})}
			</amp-story-player>
		</section>
	);
}
