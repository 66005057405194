"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Thumbnail } from "@/app/types/Thumbnail.type";

type ImgType = {
	thumbnail: Thumbnail;
	customsizes: string | undefined;
	priority?: boolean;
	breakpoints?: boolean;
};

const DEFAULT_IMAGE = "/assets/placeholder_.svg";

export default function ClientImage({
	thumbnail,
	customsizes,
	priority = false,
	breakpoints = false,
}: ImgType) {

	const aspectRatio_4_3 = thumbnail?.imageUrl?.shapes?.vintageVertical ?? DEFAULT_IMAGE;
	const aspectRatio_1_1 = thumbnail?.imageUrl?.shapes?.square ?? DEFAULT_IMAGE;
	const aspectRatio_16_9 = thumbnail?.imageUrl?.webp ?? DEFAULT_IMAGE;

	const [src, setSrc] = useState(aspectRatio_16_9);
	const load = priority ? "eager" : "lazy";

	const LoadImage = (
		<Image
			src={src}
			alt={thumbnail?.alt ?? "Imagen default"}
			width={thumbnail?.width}
			height={thumbnail?.height}
			priority={priority}
			sizes={customsizes}
			quality={75}
			loading={load}
			onError={(e) => setSrc(DEFAULT_IMAGE)}
		/>
	);

	return (
		<>
			{breakpoints ? (
				<picture>
					<source media="(min-width: 1280px)" srcSet={aspectRatio_16_9} />
					<source media="(min-width: 834px)" srcSet={aspectRatio_1_1} />
					<source media="(min-width: 320px)" srcSet={aspectRatio_4_3} />
					{LoadImage}
				</picture>
			) : (
				LoadImage
			)}
		</>
	);
}
