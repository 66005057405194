"use client";
import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext(null);

export function SocketProvider({ children, id }: any) {
	const [isConnected, setIsConnected] = useState(false);
	const [socket, setSocket] = useState<any>(null);

	useEffect(() => {
		const newSocket = io(process.env.NEXT_PUBLIC_APOLLO_SERVER_SOCKET ?? "", {
			path: "/ws/socket.io",
			transports: ["websocket"],
			extraHeaders: {
				"X-Secure-Environment": process.env.NEXT_PUBLIC_APOLLOSEC ?? "",
			},
			reconnection: true,
			reconnectionDelay: 500,
			reconnectionAttempts: 10,
			reconnectionDelayMax: 5000,
		});

		setSocket(newSocket);

		return () => {
			console.log("Disconnecting socket");
			newSocket.close();
		};
	}, []);

	useEffect(() => {
		function onConnect() {
			if (socket && socket.connected) {
				setIsConnected(true);
				socket.emit("subscribe", id);
			}
		}

		function onDisconnect() {
			setIsConnected(false);
		}

		if (socket) {
			socket.on("connect", onConnect);
			socket.on("disconnect", onDisconnect);
		}

		return () => {
			if (socket) {
				socket.off("connect", onConnect);
				socket.off("disconnect", onDisconnect);
			}
		};
	}, [id, socket]);

	return (
		<SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
	);
}

export function useSocket() {
	return useContext(SocketContext);
}
