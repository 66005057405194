import {
	useState,
	createContext,
	ReactNode,
	SetStateAction,
	Dispatch,
} from "react";
type CountDownProps = {
	children: ReactNode;
};
type InitialProps = {
	countDown: number;
	setCountDown: Dispatch<SetStateAction<number>>;
};

export const CountDownContext = createContext<InitialProps>({
	countDown: 5,
	setCountDown: () => {},
});

export const CountDownProvider = ({ children }: CountDownProps) => {
	const initialCountDown = 5;
	const [countDown, setCountDown] = useState(initialCountDown);
	return (
		<CountDownContext.Provider
			value={{
				countDown,
				setCountDown,
			}}
		>
			{children}
		</CountDownContext.Provider>
	);
};
