/**
 *
 * @param {string} imageRatio This element is the ratio of the image
 * @param {string} size The size of the image, this element is optional
 * @returns corte_16_9_400
 */

type SizeImage = "200" | "400" | "800";

export const getNameImageType = (
	initialName: string,
	imageRatio: string,
	size?: SizeImage
): string => {
	const imageType = imageRatio.split(":");
	return `${initialName}_${imageType[0]}_${imageType[1]}${
		size ? `_${size}` : ""
	}`;
};
