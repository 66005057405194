import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import slideShowStyles from "@/app/styles/Blocks/BlockSlideShow.module.scss";

// ! Skeletons library will be remove, because juan pablo is developing custom skeletons
export const LoadingSlideShow = ({ aspectRatio }: { aspectRatio: string }) => {
	return (
		<>
			{aspectRatio === "1:1" ? (
				<div className={`${slideShowStyles["slideShow__skeleton_1_1"]}`}>
					<Skeleton style={{ maxHeight: "900px", height: "100%" }} />
				</div>
			) : aspectRatio === "16:9" ? (
				<div className={`${slideShowStyles["slideShow__skeleton_16_9"]}`}>
					<Skeleton style={{ maxHeight: "900px", height: "100%" }} />
				</div>
			) : aspectRatio === "4:3" ? (
				<div className={`${slideShowStyles["slideShow__skeleton_4_3"]}`}>
					<Skeleton style={{ maxHeight: "900px", height: "100%" }} />
				</div>
			) : aspectRatio === "3:4" ? (
				<div className={`${slideShowStyles["slideShow__skeleton_3_4"]}`}>
					<Skeleton style={{ maxHeight: "900px", height: "100%" }} />
				</div>
			) : (
				<></>
			)}
		</>
	);
};
