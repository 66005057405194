"use client";
import { useEffect, useCallback, ReactNode, MouseEvent } from "react";
import dynamic from "next/dynamic";
import style from "@/app/styles/Molecules/LightBox.module.scss";
const Icon = dynamic(() => import("@/app/components/atoms/Icon/Icon"));

interface LightBoxProps {
	onClose: () => void;
	children: ReactNode;
	theme?: "dark" | "light";
	isModal?: boolean;
}

export default function LightBox({
	onClose,
	children,
	theme = "dark",
	isModal,
}: LightBoxProps) {
	const handleButtonClick = () => {
		onClose();
	};

	const handleEscKey = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape") {
				localStorage.removeItem("lbHashId");
				onClose();
			}
		},
		[onClose]
	);

	const handleBackdropClick = (event: MouseEvent<HTMLDivElement>) => {
		if (event.target === event.currentTarget && theme !== "dark") {
			localStorage.removeItem("lbHashId");
			onClose();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handleEscKey);

		return () => {
			window.removeEventListener("keydown", handleEscKey);
			document.body.style.overflow = "auto";
		};
	}, [handleEscKey]);

	document.body.style.overflow = "hidden";

	return (
		<div
			className={`
        ${style["lightBox__backdrop"]}
        ${theme === "dark" && style["lightBox__dark"]}
        ${theme === "light" && style["lightBox__light"]}
      `}
		>
			{theme === "dark" && (
				<button
					className={`${style["lightBox__closeButton"]}`}
					type="button"
					onClick={handleButtonClick}
				>
					<Icon name="close" />
				</button>
			)}
			<div
				onClick={handleBackdropClick}
				className={`${style["lightBox__wrapper"]} ${
					(isModal && style["lightBox__wrapper__modal"]) || ""
				}`}
			>
				{children}
			</div>
		</div>
	);
}
