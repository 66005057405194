"use client";
import { useContext, useEffect } from "react";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { CountDownContext } from "@/app/context/CardCountContext";
import { Term } from "@/app/types/Term.type";
import styles from "@/app/styles/Cards/CardListVideo.module.scss";
const VideoDuration = dynamic(
	() => import("@/app/components/atoms/VideoDuration/VideoDuration")
);

declare const window: WindowWithDataLayer;

export default function CardListVideo({
	title = "",
	alt = "",
	titleTag = "",
	thumbnail,
	currentVideo = {},
	cmsId = 0,
	date = 0,
	dateModified = "",
	publisher = "",
	duration = 0,
	summary = "",
	endpoint = "",
	terms = [],
	maxLength = 0,
	setCurrentVideo = undefined,
	setCurrentPath = Function,
	isPlaying = false,
	setPlaying = undefined,
	isPeP = false,
	showEndCard,
	setShowEndCard,
	dateTime,
	contentUrl,
}: any) {
	let titleRender = "";
	if (title?.length > maxLength) {
		titleRender = `${title.substring(0, maxLength)}...`;
	} else {
		titleRender = title || "Titulo en construcción";
	}
	const channelName =
		publisher === "noticieros" || publisher === "Noticieros"
			? "N+"
			: publisher ?? "N+";
	const asPath: any = usePathname();
	const { setCountDown } = useContext(CountDownContext);

	const handleOnclick = () => {
		// If the video is finish and you change the video, the end card will be hidden
		if (showEndCard) {
			setShowEndCard(false);
			setCountDown(5);
		}
		if (cmsId) {
			setPlaying(cmsId);
			setCurrentVideo(currentVideo, {
				cmsId,
				title,
				titleTag,
				summary,
				date,
				dateModified,
				image: thumbnail?.imageUrl?.webp,
				publisher,
				alt,
				term: terms,
				path: endpoint,
				dateTime,
				duration,
				contentUrl,
			});
			setCurrentPath(endpoint);
			window?.history?.pushState(window?.history?.state, "", endpoint);
			window.document.documentElement.scrollTop = 0;
			window.document.body.scrollTop = 0;

			const sessionData = window.sessionStorage.getItem("sessionData");
			let prevPath;
			if (sessionData) {
				const sDataParsed = JSON.parse(sessionData);
				prevPath = sDataParsed.prevPath;
			}
			window.dataLayer.push({
				event: "pageView",
				author: "N+ video",
				etiquetas: terms?.map((term: Term) => term.name) ?? undefined,
				page_location: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(
					/\/+$/,
					""
				)}${endpoint}`,
				page_path: endpoint,
				page_title: titleTag || title,
				page_previous: `${prevPath}`,
				secccion: endpoint,
				tipoContenido: "VOD",
				fecha_publicacion_texto: dateTime,
			});
		}
	};

	useEffect(() => {
		document.addEventListener("AMPReady", function () {
			window?.VideoSDK?.AMP?.on("playerReady", function () {
				sessionStorage.setItem("vodReady", "ready");
				// window?.VideoSDK?.AMP?.off('playerReady');
			});
		});
		window?.VideoSDK?.AMP?.on("playerReady", function () {
			sessionStorage.setItem("vodReady", "ready");
			// window?.VideoSDK?.AMP?.off('playerReady');
		});
		return () => {
			window?.VideoSDK?.AMP?.off("playerReady");
		};
	}, [asPath]);
	return (
		<span
			className={`
				${styles.card}
				${isPeP ? styles.card__pep : ""}
			`}
			data-testid="wrapper-card-lista-video"
		>
			<div
				style={{
					position: "relative",
					width: isPeP ? "100%" : "",
					height: isPeP ? "100%" : "",
				}}
				onClick={handleOnclick}
			>
				{isPlaying && (
					<div
						className={`
							${styles.card__status_wrapper}
							${isPeP ? styles.card__status_wrapper__pep : ""}
						`}
					>
						<p className={styles.card__status_wrapper__text}>REPRODUCIENDO</p>
					</div>
				)}
				<div
					className={`
						${styles.card__img}
						${isPeP ? styles.card__img__pep : ""}
					`}
				>
					<Image
						src={
							thumbnail?.imageUrl?.webp ??
							thumbnail?.imageUrl?.jpeg ??
							"/assets/placeholder_.svg"
						}
						alt={alt}
						width={148}
						height={83.25}
						loading="lazy"
						placeholder="blur"
						blurDataURL={`${
							process.env.NEXT_PUBLIC_BLURIMAGE ||
							"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
						}`}
						sizes="(min-width:414px) 41.11vw, (min-width:520px) 28.46vw, (min-width:768px) 19.27vw, (min-width:1024px) 14.45vw, (min-width: 1280px) 11.56vw, (min-width: 1366px) 10.83vw, (min-width:1440px) 10.27vw"
					/>
				</div>
				<VideoDuration duration={duration} isPlay={false} isSmall={false} />
			</div>
			<div
				className={`
					${styles.card__info}
					${isPlaying ? styles.card__info__playing : ""}
					${isPeP ? styles.card__info__pep : ""}
				`}
				onClick={handleOnclick}
			>
				<div
					className={`
						${styles.card__info__title}
						${isPeP ? styles.card__info__title__pep : ""}
					`}
				>
					<div className={styles.card__info__clamp}>
						<h2
							className={`
								${styles.card__info__title__paragraph}
								${isPeP ? styles.card__info__title__paragraph__pep : ""}
							`}
						>
							{title}
						</h2>
					</div>
				</div>
				{!isPeP && (
					<div className={styles.card__info__canal}>
						<p className={styles.card__info__canal__name}>
							{channelName
								?.replace("Nmas ", "N+ ")
								.replace("nmas-", "N+ ")
								.replace("nmas ", "N+ ") || "N+"}
						</p>
					</div>
				)}
			</div>
		</span>
	);
}
