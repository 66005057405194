import { gql } from "@apollo/client";
export default gql`
    query GetVideoDetailRelatedVideos($category: Int!, $totalVids: Int!, $priorized: [Int]) {
      getVideoDetailRelatedVideos(category: $category, totalVids: $totalVids, priorized: $priorized) {
        relatedVideos {
          title
          titleTag
          id
          summary
          duration
          date
          dateTime
          cmsId
          channel
          path
          contentUrl
          term {
            name
            url
          }
          thumbnail {
            imageUrl {
              webp
            }
            imageDesktopUrl {
              webp
            }
            alt
            caption
            width
            height
          }
          isVideo
        }
      }
    }
  `;