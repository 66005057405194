"use client";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { LoadMoreContentProvider } from "@/app/context/LoadMoreFull";
import { NewsInfo } from "./types/IndependetResponse.interface";
import { BlockDataType } from "@/app/types/BlockDataType";
const CardsIndependFull = dynamic(() => import("./CardsIndependFull"));
const ButtonMoreIndependFull = dynamic(
	() => import("./ButtonMoreIndependFull")
);

type Props = {
	newsInfo: NewsInfo[];
	theme: string;
	loading: "lazy" | "eager";
	blockData: BlockDataType;
};

const IndependentFullSize = ({ newsInfo, theme, loading, ...rest }: Props) => {
	const pathName = usePathname();
	return (
		<>
			<LoadMoreContentProvider>
				<CardsIndependFull
					newsInfo={newsInfo}
					theme={theme}
					loading={loading}
					blockData={rest?.blockData}
				/>
				<ButtonMoreIndependFull
					newsInfo={newsInfo}
					theme={theme}
					blockData={rest?.blockData}
					pathName={pathName}
				/>
			</LoadMoreContentProvider>
		</>
	);
};

export default IndependentFullSize;
