import dynamic from "next/dynamic";
import styles from "@/app/styles/Grid/Grid.module.scss";
import { socialInfo } from "@/data";
const InteractiveLink = dynamic(
	() => import("@/app/components/organisms/BloqueHeroGrid/InteractiveLink")
);
const Icon = dynamic(() => import("@/app/components/atoms/Icon/Icon"));

export const SocialMediaEnVivo = ({
	blockDataLayer,
}: {
	blockDataLayer: any;
}) => {
	return (
		<div className={styles.social_media_wrapper}>
			<h3
				className={`${styles.title_social_network} ${styles.title_social_network_grid} ${styles.title_social_network_home}`}
			>
				<span className={styles.highlighttext}>Síguenos</span> en nuestras
				redes:
			</h3>
			{/* FooterList */}
			<ul
				className={`${styles.footer_list} ${styles.footer_list_grid} ${styles.footer_list_home}`}
			>
				{socialInfo?.map((link: any, index: number) => (
					<InteractiveLink
						url={link?.link}
						title={link?.title}
						blockData={{ blockDataLayer, content_name: "LINK>" }}
						target="_blank"
						isLink={true}
						key={`Ssocial-network-${link?.title}-${index}`}
					>
						<Icon
							className={`${styles.footer__icons} ${
								link?.icon?.toLowerCase() === "threads"
									? styles["live__icon--threads"]
									: ""
							}`}
							name={link.icon}
						/>
					</InteractiveLink>
				))}
			</ul>
		</div>
	);
};
