/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import { useEffect, useState } from "react";
import handleSelectContentECommerce from "@/app/helpers/analytics/handleSelectContentECommerce";
import styles from "@/app/styles/Atoms/NotificationSnackBar.module.scss";
import classNames from "classnames";

interface NotificationSnackBarProps {
	counter?: number;
	outVisibility?: boolean;
	endNews?: boolean;
	onClick?: () => void;
	dataLayerInfo?: DataLayerProps;

	hasUpdated?: boolean;
}
interface DataLayerProps {
	path: string;
	buttonId: string;
	entries: any;
}

export default function NotificationSnackBar({
	counter = 1,
	outVisibility = false,
	onClick,
	endNews,
	dataLayerInfo,
}: NotificationSnackBarProps) {
	const [isOpened, setIsOpened] = useState(false);
	let toOne: any = null;
	function toggleClose() {
		document.getElementById("btn-updates")?.classList.add(styles?.close);
		toOne = setTimeout(() => {
			setIsOpened(false);
		}, 1000);
	}

	useEffect(() => {
		if (counter > 0) {
			setIsOpened(true);
			handleSelectContentECommerce(
				[
					{
						index: counter,
						item_brand: undefined,
						item_category: undefined,
						item_category2: dataLayerInfo?.path || "",
						item_category3: undefined,
						item_id: dataLayerInfo?.buttonId,
						item_name: "Nueva Actualizacion",
						item_variant: "",
						quantity: counter,
						item_list_id: "live_blog",
						item_list_name: "liveBlog",
					},
				],
				"live_blog",
				"liveBlog",
				"view_item_list"
			);
		} else {
			toggleClose();
		}
		return () => clearTimeout(toOne);
	}, [counter]);

	return (
		<>
			{isOpened && (
				<div id="btn-updates" className={styles?.snackBarBreaking}>
					{counter > 0 && (
						<span onClick={onClick}>
							{counter}{" "}
							{counter < 2 ? "nueva actualización" : "nuevas actualizaciones"}
						</span>
					)}
					<button onClick={toggleClose}>
						<svg
							width="14"
							height="14"
							viewBox="0 0 15 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.5 1.41L13.09 0L7.5 5.59L1.91 0L0.5 1.41L6.09 7L0.5 12.59L1.91 14L7.5 8.41L13.09 14L14.5 12.59L8.91 7L14.5 1.41Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>
			)}
		</>
	);
}

export function NotificationSnackBarSignalLive({
	hasUpdated,
	outVisibility = false,
	onClick,
}: NotificationSnackBarProps) {
	const [isOpened, setIsOpened] = useState(false);
	let toId: any = null;
	function toggleClose() {
		document.getElementById("btn-updates-signal")?.classList.add(styles?.close);
		toId = setTimeout(() => {
			setIsOpened(false);
		}, 1000);
		return () => clearTimeout(toId);
	}

	useEffect(() => {
		if (hasUpdated) {
			setIsOpened(true);
		} else {
			toggleClose();
		}
	}, [hasUpdated]);

	return (
		isOpened && (
			<div
				id="btn-updates-signal"
				className={classNames(styles?.snackBarBreaking, styles?.signal)}
			>
				{hasUpdated && <span onClick={onClick}>Señal en vivo</span>}
				<button onClick={toggleClose}>
					<svg
						width="14"
						height="14"
						viewBox="0 0 15 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M14.5 1.41L13.09 0L7.5 5.59L1.91 0L0.5 1.41L6.09 7L0.5 12.59L1.91 14L7.5 8.41L13.09 14L14.5 12.59L8.91 7L14.5 1.41Z"
							fill="white"
						/>
					</svg>
				</button>
			</div>
		)
	);
}
